import { API } from 'types/frontend/shared';

//
// 학생 관련
//
// 학생 리스트

// 교사 token에 따라, 자신이 접근 가능한 학생의 정보를 얻어옴
export const READ_STUDENT_LIST = (academyId: number): API => ({
  method: 'GET',
  path: `/academy/${academyId}/student`,
});

export const READ_DELETED_STUDENT_LIST = (academyId: number): API => ({
  method: 'POST', // 특정 학생 리스트에 대한 요청으로 예외적으로 post 처리
  path: `/academy/${academyId}/deletedStudent`,
});

// 학생 추가
export const CREATE_STUDENT = (academyId: number): API => ({
  method: 'POST',
  path: `/academy/${academyId}/student`,
});

// 학생 여러명 추가
export const CREATE_MULTIPLE_STUDENTS = (academyId: number): API => ({
  method: 'POST',
  path: `/academy/${academyId}/multipleStudents`,
});

// 학생 xlsx 추가
export const CREATE_BULK_STUDENTS = (academyId: number): API => ({
  method: 'POST',
  path: `/academy/${academyId}/bulkStudents`,
});

// 학생 상세
export const READ_STUDENT_DETAIL = (
  academyId: number,
  studentId: number,
): API => ({
  method: 'GET',
  path: `/academy/${academyId}/student/${studentId}`,
});

// 학생 수정
export const UPDATE_STUDENT = (academyId: number, studentId: number): API => ({
  method: 'PUT',
  path: `/academy/${academyId}/student/${studentId}`,
});

// 학생 상태 수정
export const UPDATE_STUDENT_STATUS = (
  academyId: number,
  studentId: number,
): API => ({
  method: 'PUT',
  path: `/academy/${academyId}/student/${studentId}/status`,
});

// 학생 삭제
export const DELETE_STUDENT = (academyId: number, studentId: number): API => ({
  method: 'DELETE',
  path: `/academy/${academyId}/student/${studentId}`,
});

export const UPDATE_STUDENT_CLS_ASSIGNMENT = (
  academyId: number,
  studentId: number,
): API => ({
  method: 'PUT',
  path: `/academy/${academyId}/student/${studentId}/classAssignment`,
});

// 학부모의 더보기에서 다니는 학생들의 반이름 리스트를 얻을 때
export const READ_STUDENT_CLS_NAME_LIST = (academyId: number): API => ({
  method: 'GET',
  path: `/academy/${academyId}/student/className`,
});

// 학부모가 채팅방을 개설하기 위해 특정 학생과 연결된 교사 정보를 얻어온다.
export const READ_STUDENT_TEACHER_LIST = (
  academyId: number,
  studentId: number,
): API => ({
  method: 'GET',
  path: `/academy/${academyId}/student/${studentId}/teacher`,
});

export const READ_UNLINKED_STUDENT_LIST = (academyId: number): API => ({
  method: 'GET',
  path: `/academy/${academyId}/student/unlinkedStudent`,
});

export const DO_SEND_INVITATION_MESSAGE = (academyId: number): API => ({
  method: 'POST',
  path: `/academy/${academyId}/student/sendInvitationMessage`,
});

// 자료 다운로드
export const DOWNLOAD_STUDENT_LIST = (academyId: number): string =>
  `/academy/${academyId}/student/download`;
