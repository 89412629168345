import { MenuCategoryT } from 'types/frontend/Menu';

class Str {
  static hyphen(phoneNumber: string | null | undefined) {
    if (!phoneNumber) return '-';

    return phoneNumber
      .replace(/[^0-9]/g, '')
      .replace(/^02(\d{3,4})(\d{4})$/, '02-$1-$2')
      .replace(/^(\d{3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
  }

  static hyphenForCorpRegNo(corpRegNo: string | null | undefined) {
    if (!corpRegNo) return '-';

    return corpRegNo
      .replace(/[^0-9]/g, '') // Remove any non-digit characters
      .replace(/^(\d{3})(\d{2})(\d{5})$/, '$1-$2-$3'); // Format into 3-2-5 pattern
  }

  static randomNumberStr(length: number): string {
    let result = '';
    const characters = '0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  static randomAlphaNumericStr(length: number): string {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  static getMenuCategory(path: string): MenuCategoryT | null {
    const pathItems = path.split('/');
    console.log(pathItems);
    if (pathItems.length < 3) return null;
    if (pathItems[1] !== 'teacherSide') return null;
    if (pathItems[2] === 'bill') return 'bill';

    if (pathItems[2] === 'management') return 'management';
    if (pathItems[2] === 'news') return 'news';
    if (pathItems[2] === 'attendance') return 'attendance';

    if (pathItems[2] === 'homework') return 'homeworkAndSchedule';
    if (pathItems[2] === 'counsel') return 'counsel';
    if (pathItems[2] === 'message') return 'message';

    return null;
  }

  static hasDigitsOnly(str) {
    return /^[0-9.,]+$/.test(str);
  }

  static moneyToHangeul(str: string | number) {
    let moneyStr: string = typeof str === 'string' ? str : str.toString();

    // console.log('moneyStr', moneyStr);
    let sign = '';

    if (moneyStr.startsWith('-')) {
      // 첫글자가 부호이면
      sign = '-';
      moneyStr = moneyStr.substring(1);
    }

    if (!Str.hasDigitsOnly(moneyStr)) return '';
    const hanA = [
      '',
      '일',
      '이',
      '삼',
      '사',
      '오',
      '육',
      '칠',
      '팔',
      '구',
      '십',
    ];
    const danA = [
      '',
      '십',
      '백',
      '천',
      '',
      '십',
      '백',
      '천',
      '',
      '십',
      '백',
      '천',
    ];

    let result = '';

    for (let i = 0; i < moneyStr.length; i++) {
      let str = '';
      const han = hanA[moneyStr.charAt(moneyStr.length - (i + 1))];
      if (han !== '') str = han + danA[i];
      if (i === 4) str += '만';
      if (i === 8) str += '억';
      result = str + result;
    }
    // console.log(`money to hangeul ${sign + result}`);
    if ((sign + result).length > 0) return sign + result + '원';
    return '';
  }

  static formatNumberWithCommas(x: number) {
    return x.toLocaleString('en-US'); // This will ensure the comma as a thousand separator
  }

  // 콤마로 구분된 id성격의 number들을 구해다줌
  static getNumberArrFromStr(numberStr: string): number[] {
    return numberStr
      .split(',')
      .map((str) => parseInt(str.trim()))
      .filter((id) => !!id && id > 0);
  }

  static isYouTubeLink(str: string): boolean {
    const pattern = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;
    return pattern.test(str);
  }

  static isVimeoLink = (url: string): boolean => {
    const pattern = /^(https?:\/\/)?(www\.)?(vimeo\.com)\/.+$/;
    return pattern.test(url);
  };

  static isVideoLink = (url: string): boolean => {
    return Str.isYouTubeLink(url) || Str.isVimeoLink(url);
  };

  static isWebLink(str: string): boolean {
    const pattern = /^https?:\/\/.+/;
    return pattern.test(str);
  }
}
export default Str;
