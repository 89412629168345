const allowedExtensions = [
  '.jpeg',
  '.jpg',
  '.png',
  '.gif',
  '.bmp', // Images
  '.aac',
  '.mp3',
  '.oga',
  '.ogg',
  '.wav',
  '.wma',
  '.m4a', // Audio
  '.avi',
  '.wmv',
  '.mp4',
  '.mpeg',
  '.ogv',
  '.mov',
  '.mkv', // Video
  '.txt',
  '.csv',
  '.pdf',
  '.doc',
  '.ppt',
  '.xls',
  '.docx',
  '.pptx',
  '.xlsx', // Documents
  '.zip', // Archive
];

export { allowedExtensions };
