import React from 'react';
import { Route } from 'react-router-dom';
const TeacherNews = React.lazy(
  () => import('pages/teacherSide/TeacherNews/TeacherNews'),
);

// attendance

// 학생 출결 목록
const ARListOwnedByTeacher = React.lazy(
  () =>
    import(
      'pages/teacherSide/attendance/ARListOwnedByTeacher/ARListOwnedByTeacher'
    ),
);

const ARListOfToday = React.lazy(
  () => import('pages/teacherSide/attendance/ARListOfToday/ARListOfToday'),
);

const ARListByDate = React.lazy(
  () => import('pages/teacherSide/attendance/ARListByDate/ARListByDate'),
);

const ARListOfAllStudents = React.lazy(
  () =>
    import(
      'pages/teacherSide/attendance/ARListOfAllStudents/ARListOfAllStudents'
    ),
);

// 새 출결 기록
const NullComponent = () => null;

const WriteAttRecord = React.lazy(
  () =>
    new Promise((resolve) => {
      resolve({ default: NullComponent as never });
    }),
);

// 교사 근태 관리
const AttRecordListOfTeacher = React.lazy(
  () =>
    import(
      'pages/teacherSide/attendance/AttRecordListOfTeacher/AttRecordListOfTeacher'
    ),
);
const AttRecordListOfAllTeachers = React.lazy(
  () =>
    import(
      'pages/teacherSide/attendance/AttRecordListOfAllTeachers/AttRecordListOfAllTeachers'
    ),
);

// homework and schedule
const HomeworkList = React.lazy(
  () => import('pages/teacherSide/homework/HomeworkList/HomeworkList'),
);

const HomeworkSearch = React.lazy(
  () => import('pages/teacherSide/homework/HomeworkSearch/HomeworkSearch'),
);
const HomeworkPresetAdd = React.lazy(
  () =>
    import('pages/teacherSide/homework/HomeworkPresetAdd/HomeworkPresetAdd'),
);

// 상담 관련
const CounselLog = React.lazy(
  () => import('pages/teacherSide/counsel/CounselLog/CounselLog'),
);

const CounselLogCount = React.lazy(
  () => import('pages/teacherSide/counsel/CounselLogCount/CounselLogCount'),
);

// message
const MessageSend = React.lazy(
  () => import('pages/teacherSide/message/MessageSend/MessageSend'),
);
const MessageHistory = React.lazy(
  () => import('pages/teacherSide/message/MessageHistory/MessageHistory'),
);
//
// 4) 교사화면 탭
//

const RouteListTeacherSide = (
  <Route path="/teacherSide/">
    <Route path="news/">
      <Route
        path="generalBoard"
        element={<TeacherNews target="generalBoard" />}
      />
      <Route path="classBoard" element={<TeacherNews target="classBoard" />} />
      <Route path="note" element={<TeacherNews target="note" />} />
    </Route>
    <Route path="attendance/">
      <Route path="student/list" element={<ARListOwnedByTeacher />} />
      <Route path="student/ofToday" element={<ARListOfToday />} />
      <Route path="student/byDate" element={<ARListByDate />} />
      <Route path="student/all" element={<ARListOfAllStudents />} />
      <Route path="writeAttRecord" element={<WriteAttRecord />} />
      <Route path="teacher/list" element={<AttRecordListOfTeacher />} />
      <Route path="teacher/all" element={<AttRecordListOfAllTeachers />} />
    </Route>
    <Route path="homework/">
      <Route path="list" element={<HomeworkList />} />
      <Route path="search" element={<HomeworkSearch />} />
      <Route path="preset/add" element={<HomeworkPresetAdd />} />
    </Route>

    <Route path="counsel/">
      <Route path="log" element={<CounselLog />} />
      <Route path="logCount" element={<CounselLogCount />} />
    </Route>

    <Route path="message/">
      <Route path="send" element={<MessageSend />} />
      <Route path="history" element={<MessageHistory />} />
    </Route>
  </Route>
);

export default RouteListTeacherSide;
